import { useEffect } from "react";
import Viewer from 'viewerjs';
import styled from 'styled-components'


const GalleryMeta = [
    {
        src: 'digital/0.png',
        alt: 'Digital drawing.'
    },
    {
        src: 'digital/1.png',
        alt: 'Digital drawing.'
    },
    {
        src: 'digital/2.png',
        alt: 'Digital drawing.'
    },
    {
        src: 'digital/3.png',
        alt: 'Digital drawing.'
    },
    {
        src: 'digital/4.png',
        alt: 'Digital drawing.'
    },
    {
        src: 'digital/5.png',
        alt: 'Digital drawing.'
    },
    {
        src: 'not-digital/4.png',
        alt: 'A drawing.'
    },
    {
        src: 'not-digital/5.png',
        alt: 'A drawing.'
    },
]

const Image = styled.img`
    width: 100%;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    border-radius: 4px;
`

const ImageList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    justify-content: center;
    gap: 6px;
    width: 100%;
    margin-left: 0;
    padding-left: 0;
`

const ImageListItem = styled.li`
    width: 400px;
    max-width: calc(100% - 40px);
`

const Gallery = () => {

    useEffect(() => {
        new Viewer(document.getElementById('images'), {
            url(image) {
                return image.src.replace('_tn.jpg', '.png')
            }
        });
    }, [])

    return <div>
        <ImageList id="images">
            {
                GalleryMeta.map(({ src, alt }) => <ImageListItem key={src}>
                    <Image src={'img/' + src.replace('.png', '_tn.jpg')} alt={alt} />
                </ImageListItem>)
            }
        </ImageList>
    </div>
}


export default Gallery;