import styled from 'styled-components'
import { useNavigate } from "react-router-dom";
import { Products } from '../Products';


const Image = styled.img`
    width: 100%;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    border-radius: 4px;
`

const ImageList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;  
    gap: 6px;
    justify-content: center;
`

const ImageListItem = styled.li`
    width: 400px;
    max-width: calc(100% - 40px);
`

const Title = styled.span`
    display: inline-block;
    width: 100%;
    text-align: center;
    font-size: 18px;
`

const Price = styled.span`
    display: inline-block;
    width: 100%;
    text-align: center;
    color: #ae6019;
`

const Shop = () => {
    const navigate = useNavigate();
    return <div>
        <ImageList id="images">
            {
                Products.map(({ src, alt, title, price }, ix) => <ImageListItem key={src} onClick={() => navigate("/detail/" + ix)}>
                    <Image src={'img/' + src.replace('.png', '_tn.jpg')} alt={alt} />
                    <Title>{title}</Title>
                    <Price>€{price.toFixed(2)}</Price>
                </ImageListItem>)
            }
        </ImageList>
    </div>
}


export default Shop;