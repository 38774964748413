
import styled from 'styled-components'
import { Routes, Route, Link, useLocation } from "react-router-dom";
import Gallery from './pages/Gallery';
import { AboutMe } from './pages/AboutMe';
import Shop from './pages/Shop';
import { Detail } from './pages/Detail';


const HeaderWrapper = styled.div`
    position: fixed;
    width: 100%;
    top: 0;
`

const HeaderImage = styled.div`
    height: 150px;
    max-height: 20vh;
    width: 100%;
    background-image: url('/manuscript.jpeg');
    background-size: 51%;
    background-position-y: 26%;

    ${() => {
        return window.isMobile ? 'background-size: 100%;' : ''
    }}
`

const Header = styled.div`
    height: 50px;
    width: 100%;
    background-color: #f2f2f2;
    display: flex;
    justify-content: center;
    flex-direction: row;
`

const Content = styled.div`
    max-width: 1400px;
    padding: 2vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 168px;
`

const Links = styled.div`
    flex: 1;
    display: flex;
    align-items: flex-end;
    text-align: center;
    gap: 42px;
    padding-bottom: 12px;
    padding-left: 40px;

    ${() => {
        return window.isMobile ? 'gap: 12px;' : ''
    }}
`

const StyledLink = styled(Link)`
  color: black;
  text-decoration: none;
  border-bottom: 2px solid #00000000;
`

const NameContainer = styled.div`
    flex: 1;
    text-align: center;
    margin-top: 4px;

    ${() => {
        return window.isMobile ? 'margin-top: 14px; margin-left: 20px; flex: 2;' : ''
    }}
`

const NameTitle = styled.span`
    font-size: 36px;
    
    ${() => {
        return window.isMobile ? 'font-size: 20px;' : ''
    }}
`

const Main = () => {
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    return <>
        <HeaderWrapper className="App" >
            <HeaderImage/>
            <Header>
            <Links>
                <StyledLink to='/' style={{ borderColor: splitLocation[1] === '' ? 'black' : '#00000000' }}>Gallery</StyledLink>
                {/* <StyledLink to='/shop' style={{ borderWidth: splitLocation[1] === 'shop' ? borderWidth : 0 }}>Shop</StyledLink> */}
                <StyledLink to='/about' style={{ borderColor: splitLocation[1] === 'about' ? 'black' : '#00000000' }}>About</StyledLink>
            </Links>
            <NameContainer><StyledLink to='/'><NameTitle>Ilse Douma</NameTitle></StyledLink></NameContainer>
            <div style={{ flex: 1 }}>
                {/* <ShoppingCart /> */}
            </div>
            </Header>
        </HeaderWrapper>
        <Content>
            <Routes>
                <Route exact path="/" element={<Gallery />} />
                <Route path="/shop" element={<Shop />} />
                <Route path="/about" element={<AboutMe />} />
                <Route path="/detail/:id" element={<Detail />} />
            </Routes>
        </Content>
    </>
}

export default Main;