import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    gap: 16px;
    justify-content: center;
    margin-top: 32px;

    ${() => {
        return window.isMobile ? 'flex-direction: column; align-items: center;' : ''
    }}
`

const AboutMeText = styled.div`
    background-color: #d6c7b7;
    border-radius: 4px;
    padding: 12px;
    height: fit-content;
    max-width: 400px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
`

const AboutMeImg = styled.img`
    max-width: 500px;
    width: 100%;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
`

export const AboutMe = () => {

    return <Container>
        <AboutMeText>
        Exact daar waar de middeleeuwen de renaissance ontmoet, dat is waar ik leef.<br/>
        Met mijn studie Engelse literatuur heb ik mij jaren lang verdiept in middeleeuwse teksten, die liefde vertaal ik nu op het doek.<br/>
        Mijn focus ligt op het maken van portretten op een tijdloze en klassieke manier, je hoort niet te zien dat ze gemaakt zijn in de 21e eeuw.<br/>
        Olieverf is mijn medium naar keuze, maar een tekening op papier vind ik ook heerlijk om te maken.<br/>
        </AboutMeText>
        <AboutMeImg src='img/about_me.jpg' />
    </Container>
}