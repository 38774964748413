import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Products } from '../Products';
import Viewer from 'viewerjs';
import { styled } from 'styled-components';
import { Notifications } from '../Notifications';
import { CartContext } from '../CartContext';

const Container = styled.div`
    display: flex;
    justify-content: center;
    margin-block-start: 1em;
`

const ProductDescription = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: 24px;
    max-width: 400px;
`

const ProductImage = styled.img`
    width: 400px;
    cursor: pointer;
`

const Price = styled.span`
    color: #ae6019;
`

const StyledInput = styled.input`
  width: 60px;
  padding: 4px;
  
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
  box-sizing: border-box;
  border-radius: 4px;
`
const StyledButton = styled.button`
    background-color: #d06a56;
    border: none;
    color: white;
    padding: 8px 12px;
    text-decoration: none;
    cursor: pointer;
    border-radius: 4px;
`


export const Detail = () => {
    const { id } = useParams();
    const [amount, setAmount] = useState(1);

    const { cart, setCart } = useContext(CartContext);

    useEffect(() => {
        new Viewer(document.getElementById('product-img'), {
            url(image) {
                return image.src.replace('_tn.jpg', '.png')
            }
        });
    }, [])

    const product = Products[id];
    if (!product) return null;

    const addToCart = () => {
        const newCart = [...cart];

        const cartHasProduct = newCart.find(item => item.id === id);
        if (cartHasProduct) {
            cartHasProduct.amount += amount;
        }
        else {
            newCart.push({
                product, amount, id,
            })
        }
        setCart(newCart)
        Notifications.getInstance().notifier.success(`Added ${product.title} to your cart`)
    }

    return <Container>
        <ProductImage id='product-img' src={'/img/' + product.src} alt={product.alt} />
        <ProductDescription>
            <Price>€{product.price.toFixed(2)}</Price>
            <span>Hey, you, is een Fine Art Print in multicolour. Een mooie combinatie met ‘Have some fun’. Iedere Art Print wordt giclée gedrukt, dit is een reproductie van een kunstwerk van een zeer hoge kwaliteit (scroll naar beneden voor meer informatie over giclée drukwerk).</span>
            <div style={{ display: 'flex', gap: 8 }}>
                <StyledInput min={1} max={100} type='number' value={amount} 
                    onBlur={() => setAmount(Math.max(1, Math.min(100, Number(amount))))}
                    onChange={(e) => setAmount(e.target.value)}
                    />
                <StyledButton onClick={addToCart}>Add to cart</StyledButton>
            </div>
        </ProductDescription>
    </Container>
}