import './App.css';
import 'viewerjs/dist/viewer.css';

import { BrowserRouter } from "react-router-dom";
import Main from './Main'
import { CartContext } from './CartContext';
import { useEffect, useState } from 'react';

function App() {
  const [cart, setCart] = useState([]);
  const [, reRender] = useState({});

  useEffect(() => {
    window.addEventListener('resize', () => {
      window.isMobile = window.mobileCheck();
      reRender({});
    })
  }, [])

  return (
    <BrowserRouter basename="/">
      <CartContext.Provider value={{cart, setCart}}>
        <Main />
      </CartContext.Provider>
    </BrowserRouter>
  );
}

export default App;
