import AWN from "awesome-notifications";

export class Notifications {

    static getInstance() {
        if (this._instance) return this._instance;

        this._instance = new Notifications();
        return this._instance;
    }

    constructor() {
        this.notifier = new AWN({
            position: 'top-right',
            maxNotifications: 1,
        })
    }
}